import React from "react";
import Logo from "../../../assets/images/samsung_care_plus.png";
const ModalHeaderDobleAuth = () => {
  return (
    <div className="flex justify-center">
      <img alt={`logo_samsung`} src={Logo} className="max-w-56" />
    </div>
  );
};

export default ModalHeaderDobleAuth;
