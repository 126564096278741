import React from "react";
import { Controller, useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import PrimaryButton from "../../../components/buttons/PrimaryButton";
import Input from "../../../components/inputs/Input";
import { useCreateAdvisory } from "../../../services/microservice/advisory/use-advisory";
import { useModalContext } from "../../../providers/modal/modal.provider";
import ModalHeader from "../../../components/modalv2/components/ModalHeader";
import ModalBody from "../../../components/modalv2/components/ModalBody";

interface IFormInput {
  name: string;
  email: string;
  phone: string;
}

const schemaAdivisory = yup.object().shape({
  name: yup.string().required("* Requerido"),
  email: yup
    .string()
    .email("* Ingrese un email válido")
    .required("* Requerido"),
  phone: yup
    .string()
    .matches(/^\d+$/, { message: "* Solo se admiten números" })
    .min(10, "* Mínimo 10 dígitos")
    .max(10, "* Máximo 10 dígitos")
    .required("* Requerido"),
});

const AdvisorySection = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schemaAdivisory),
  });
  const { mutateAsync: createAdvisory, isLoading } = useCreateAdvisory();
  const { setShow } = useModalContext();
  const onSubmit: SubmitHandler<IFormInput> = async (val) => {
    try {
      const res = await createAdvisory(val);
      setShow((prevState) => ({
        ...prevState,
        visible: true,
        header: <ModalHeader success={true} />,
        content: (
          <ModalBody
            title="Solicitud enviada exitosamente"
            message="Hemos recibido tu información, durante los próximos días nos comunicaremos."
          />
        ),
      }));
    } catch (error) {
      setShow((prevState) => ({
        ...prevState,
        visible: true,
        header: <ModalHeader success={false} />,
        content: (
          <ModalBody
            title="Ocurrio un error"
            message="Lo sentimos, ocurrió un error intentaló más tarde."
          />
        ),
      }));
    }
  };

  return (
    <section className="flex w-full  justify-center p-0">
      <div className="container w-full py-10 xl:px-24 2xl:px-20">
        <div className="mx-10 grid gap-4 border-t-2 border-gray py-10 lg:mx-0 xl:grid-cols-7 2xl:grid-cols-9">
          <div className="mb-5 xl:col-span-4 2xl:col-span-5">
            <h2 className="text-center text-4xl  font-bold sm:text-left">
              Solicita una asesoría para crear la solución adecuada para tus
              clientes
            </h2>
          </div>
          <div className="ml-auto xl:col-span-3 2xl:col-span-4 ">
            <p className="text-justify text-lg  font-normal text-secondary sm:text-left">
              Construimos productos y servicios a su medida con nuestra flexible
              plataforma tecnológica y de servicios.
            </p>
          </div>
        </div>
        <form
          className="mx-10 grid gap-4  border-gray lg:mx-0 xl:grid-cols-7 2xl:grid-cols-9"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Nombre"
                classNameContainer="2xl:col-span-3 xl:col-span-3"
                classNameInput="w-full text-textBlack"
                error={errors.name?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Email"
                classNameContainer="2xl:col-span-3 xl:col-span-3"
                classNameInput="w-full text-textBlack"
                error={errors.email?.message}
                {...field}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Input
                placeholder="Celular"
                classNameContainer="2xl:col-span-2 xl:col-span-2"
                classNameInput="w-full text-textBlack"
                error={errors.phone?.message}
                {...field}
              />
            )}
          />
          <PrimaryButton
            title="Pedir asesoría"
            className="rounded-3xl bg-buttonBlack font-normal text-white xl:col-span-1 2xl:col-span-1"
            type="submit"
            loading={isLoading}
            disabled={isLoading}
          />
        </form>
      </div>
    </section>
  );
};
export default AdvisorySection;
