import { AxiosResponse } from "axios";

import { microserviceApiRoutes } from "../../microservice-api.routes";
import { microserviceApiAxios } from "../../microservice-api.axios";
import { IGenerateCodeReq, IValidateCodeReq } from "./code-service.interface";

export const generateCode = (data: IGenerateCodeReq) => {
  const url = microserviceApiRoutes.v1.claim.code.generate();
  return microserviceApiAxios
    .post<Error, AxiosResponse>(url, data)
    .then((res) => res.data);
};

export const validateCode = (data: IValidateCodeReq) => {
  const url = microserviceApiRoutes.v1.claim.code.validate();
  return microserviceApiAxios
    .post<Error, AxiosResponse>(url, data)
    .then((res) => res.data);
};
