import React from "react";
import { Helmet } from "react-helmet";
import Icon from "../../assets/images/favicon_sbs.png";
import Banner from "./components/banner";
import Navbar from "./components/navbar";
import Claims from "../../components/claims/Claims";
import Footer from "./components/footer";

const ClaimsSBSPage = () => {
  return (
    <>
      <Helmet>
        <title>Reclamaciones | Itau </title>
        <meta
          name="description"
          content="¡Es muy simple! Con tan solo 5 sencillos pasos puedes realizar tu reclamación."
        />
        <link rel="icon" href={Icon} />
      </Helmet>
      <Navbar />
      <Banner />
      <section
        className="flex w-full justify-center p-0"
        style={{
          backgroundImage: "url(/reclamation/itau/background_contactUs.png)",
          backgroundPosition: "left bottom",
          backgroundRepeat: "no-repeat",
          left: 0,
        }}
      >
        <div className="container w-full px-4 py-20 lg:px-20">
          <div className="rounded-xl py-5 shadow-xl lg:px-10">
            <Claims
              colorPrimary="#9E1C64"
              privacyPolicy={
                <p className="my-4 text-sm font-light text-secondary">
                  * Tus datos están protegidos mediante nuestra política de
                  protección de datos, revisala{" "}
                  <a
                    href="https://files-statics-protegeme.s3.amazonaws.com/Aviso_de_Privacidad_SBS_Seguros_Colombia_2.pdf"
                    style={{ color: "#9E1C64" }}
                    target="_blank"
                  >
                    aquí
                  </a>
                  .
                </p>
              }
            />
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ClaimsSBSPage;
