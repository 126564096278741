import React from "react";
import { Link } from "react-router-dom";

import { ClausedCard } from "./claused.component";
import PrimaryButton from "../../../components/buttons/PrimaryButton";

const CardClaused = ({
  buttonTitle,
  secureImage,
  buttonNavPath,
}: ClausedCard) => {
  return (
    <div className="my-4 flex flex-col items-center justify-center rounded-2xl bg-white p-10 py-20 shadow-xl">
      <div className="mb-10 grid grid-cols-1 gap-4 ">{secureImage}</div>
      <Link to={buttonNavPath}>
        <PrimaryButton
          title={buttonTitle}
          className="rounded-xl bg-buttonPrimary text-white"
        />
      </Link>
    </div>
  );
};

export default CardClaused;
