import React from "react";
import { Helmet } from "react-helmet";

const TermsPage = () => {
  return (
    <>
      <Helmet>
        <title>Términos y condiciones | Acinco</title>
        <meta name="description" content="Términos y condiciones" />
      </Helmet>
      <div className="h-screen w-screen">
        <iframe
          title="terms"
          src="https://files-statics-protegeme.s3.amazonaws.com/P1648+EQUIPOS+TELEFONIA+CELULAR_MAR+2022.pdf"
          className="h-full w-full"
        />
      </div>
    </>
  );
};

export default TermsPage;
