import React from "react";
import { Helmet } from "react-helmet";

const PrivacyPolicyPage = () => {
  return (
    <>
      <Helmet>
        <title>Política de privacidad | Acinco</title>
        <meta name="description" content="Política de privacidad" />
      </Helmet>
      <div className="h-screen w-screen">
        <iframe
          title="terms"
          src="https://files-statics-protegeme.s3.amazonaws.com/Politica+deprotecciondedatos-min.pdf"
          className="h-full w-full"
        />
      </div>
    </>
  );
};

export default PrivacyPolicyPage;
