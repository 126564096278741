import { useMutation, QueryOptions } from "react-query";

import { generateCode, validateCode } from "./code-service";
import {
  IUseGenerateCodeService,
  IUseValidateCodeService,
} from "./use-code.interface";

export const useGenerateCode = (config?: QueryOptions) => {
  return useMutation(
    (data: IUseGenerateCodeService) => generateCode(data).then((res) => res),
    config,
  );
};

export const useValidateCode = (config?: QueryOptions) => {
  return useMutation(
    (data: IUseValidateCodeService) => validateCode(data).then((res) => res),
    config,
  );
};
