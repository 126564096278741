import React from "react";

interface IModalBodyProps {
  title: string;
  message: string;
}

const ModalBody = ({ message, title }: IModalBodyProps) => {
  return (
    <div className=" w-full justify-center ">
      <h3 className="text-center text-2xl font-bold text-textBlack">{title}</h3>
      <p className="mx-10 my-5 text-center text-xl text-textBlack">{message}</p>
      <div className="mx-auto w-5/6">
        <p className="text-xs">
          Si tienes cualquier consulta, o deseas hacerle seguimiento a tu
          reclamación, puedes contactar a nuestras líneas de atención al
          cliente:
        </p>
        <ul className="ml-10 list-disc">
          <li className="text-xs">Línea nacional 01 8000 411 720</li>
          <li className="text-xs">Línea WhatsApp +5715142355</li>
          <li className="text-xs">Línea Fija Bogotá (601)3905567</li>
          <li className="text-xs">
            Horarios de atención: Lunes a Viernes 8:00 a.m-6:00 p.m. Sábados
            8:00a.m.-2:00 p.m.
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ModalBody;
