import React, { useCallback, useEffect } from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import AutoScroll from "embla-carousel-auto-scroll";

import "./embla.css";

interface Slide {
  key: string;
  component: any;
}

interface Props {
  slides: Slide[];
  options?: EmblaOptionsType;
}

const Carousel: React.FC<Props> = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options, [
    AutoScroll({ playOnInit: false }),
  ]);

  const toggleAutoplay = useCallback(() => {
    const autoScroll = emblaApi?.plugins()?.autoScroll;
    if (!autoScroll) return;
    const playOrStop = autoScroll.isPlaying()
      ? autoScroll.stop
      : autoScroll.play;
    playOrStop();
  }, [emblaApi]);

  useEffect(() => {
    setTimeout(() => toggleAutoplay(), 500);
  });

  return (
    <div className="embla ">
      <div className="embla__viewport" ref={emblaRef}>
        <div className="embla__container">
          {slides.map((component, index) => (
            <div className="embla__slide" key={component.key}>
              <div className="embla__slide__number">{component.component}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
