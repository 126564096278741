import React from "react";
import Logo from "../../../assets/images/samsung_care_plus.png";

const Navbar = () => {
  return (
    <nav className={`fixed z-40 w-full bg-white`}>
      <div className="max-w-screen flex">
        <div className="flex w-full items-center justify-between px-5 lg:justify-center lg:px-0 xl:w-2/6 2xl:w-2/6">
          <div className="w-56 pt-2">
            <img alt="acinco_logo" src={Logo} className="img-fluid" />
          </div>
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
