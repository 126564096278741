import { useMutation, QueryOptions } from "react-query";

import { generateAdvisory } from "./advisory-service";
import { IUseCreateAdvisoryService } from "./use-advisory.interface";

export const useCreateAdvisory = (config?: QueryOptions) => {
  return useMutation(
    (data: IUseCreateAdvisoryService) =>
      generateAdvisory(data).then((res) => res.data),
    config,
  );
};
