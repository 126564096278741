import React, { useState } from "react";

import Input from "../../inputs/Input";
import PrimaryButton from "../../buttons/PrimaryButton";
import { useValidateCode } from "../../../services/microservice/claim/code/use-code";

interface IModalBody {
  submit: () => void;
  email?: string;
}

const ModalBodyDobleAuth = ({ submit, email }: IModalBody) => {
  const [code, setCode] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync: validateCodeRequest } = useValidateCode();

  const validateCode = async () => {
    try {
      setIsLoading(true);
      const response = await validateCodeRequest({ code });
      submit();
    } catch (error) {
      submit();
      setCode("");
    }
  };

  const handleChangeValueCode = (e) => {
    setCode(e.target.value);
  };

  const validate = () => {
    if (code && code.length === 6) {
      setError(false);
      validateCode();
    } else {
      setError(true);
    }
  };

  return (
    <div className="w-full">
      <h3 className="my-2 text-center text-2xl font-semibold">
        Código de verificación
      </h3>
      <p className="text-normal mx-auto my-2 px-10 text-center text-lg">
        Para realizar la reclamación ingresar el código de verificación enviado
        a su correo {email}
      </p>
      <Input
        classNameContainer="w-full"
        classNameInput="w-full"
        placeholder="Código de verificación"
        onChange={handleChangeValueCode}
        required
        type="number"
        error={error && "* Requerido"}
        value={code}
      />
      <PrimaryButton
        title={"Confirmar"}
        type="submit"
        className="absolute -bottom-6 -right-28 rounded-2xl bg-buttonBlack px-5 text-white sm:-right-60"
        onClick={() => validate()}
        loading={isLoading}
        disabled={isLoading}
      />
    </div>
  );
};
export default ModalBodyDobleAuth;
