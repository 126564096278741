import React from "react";
import { useStepContext } from "./stepper.provider";
import LoginStepComponent from "./steps/login.step.component";
import PlanStepComponent from "./steps/plan.step.component";
import DocumentStepComponent from "./steps/document.step.component";
import AditionalStepComponent from "./steps/aditional.step.component";
import SendStepComponet from "./steps/send.step.component";

interface ICustomStepper {
  colorPrimary: string;
  privacyPolicy?: React.ReactNode;
  dobleAuth?: boolean;
}

const StepperBody = ({
  colorPrimary,
  privacyPolicy,
  dobleAuth,
}: ICustomStepper) => {
  const { currentStep } = useStepContext();
  const firstStep = (
    <LoginStepComponent
      colorPrimary={colorPrimary}
      privacyPolicy={privacyPolicy}
    />
  );
  const secondStep = (
    <PlanStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const thirdStep = (
    <DocumentStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const fourthStep = (
    <AditionalStepComponent colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  const fiveStep = (
    <SendStepComponet colorPrimary={colorPrimary} dobleAuth={dobleAuth} />
  );
  return (
    <section className="mx-auto w-3/4 border-t pt-10 lg:w-2/4 ">
      <div className="mx-auto">
        {currentStep === 1 && firstStep}
        {currentStep === 2 && secondStep}
        {currentStep === 3 && thirdStep}
        {currentStep === 4 && fourthStep}
        {currentStep === 5 && fiveStep}
      </div>
    </section>
  );
};

export default StepperBody;
