import React, { TextareaHTMLAttributes } from "react";

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  classNameContainer?: string;
  classNameInput?: string;
  classNameIcon?: string;
  icon?: React.ReactElement | React.ReactNode;
  error?: string;
  required?: boolean;
}

const TextArea = ({
  classNameContainer,
  classNameInput,
  icon,
  classNameIcon,
  error,
  required = false,
  ...props
}: TextAreaProps) => {
  return (
    <div className={`${classNameContainer ? classNameContainer : ""} relative`}>
      <div className="h-10">
        <p className="my-0 py-0 text-base">
          {props.placeholder}{" "}
          {required && <span className="text-3xl text-critical">*</span>}{" "}
          {error && (
            <span className="my-0 py-0 text-base text-critical">{error}</span>
          )}
        </p>
      </div>
      <textarea
        className={`${classNameInput ? classNameInput : ""} rounded-lg bg-gray px-3 py-2  text-textBlack`}
        {...props}
      />
      {icon && (
        <div
          className={`${classNameIcon ? classNameIcon : ""} absolute right-0 top-0 h-full rounded-r-md`}
        >
          {icon}
        </div>
      )}
    </div>
  );
};

export default TextArea;
