import React from "react";

import HomePage from "../../pages/home/Home.page";
import AboutUsPage from "../../pages/aboutus/AboutUs.page";
import ProductsPage from "../../pages/products/Products.page";
import ClaimsAxaPage from "../../pages/claimsAxa/claimsAxa.page";
import TermsPage from "../../pages/terms/terms.page";
import PrivacyPolicyPage from "../../pages/privacyPolicy/privacyPolicy";
import ClaimsSBSPage from "../../pages/claimsSBS/claimsSBS.page";
import ClaimsSamsungCarePlusPage from "../../pages/claimsSamsungCarePlus/claimsSamsungCarePlus.page";
import ClaimsPage from "../../pages/claims/ClaimsGeneral.page";
import ClausedPage from "../../pages/claused/claused.page";

const routes = [
  {
    path: "/",
    component: HomePage,
    exact: true,
  },
  {
    path: "nosotros",
    component: AboutUsPage,
    exact: true,
  },
  {
    path: "productos",
    component: ProductsPage,
    exact: true,
  },
  {
    path: "tramites-y-reclamaciones",
    component: ClaimsPage,
    exact: true,
  },
  {
    path: "tramites-y-reclamaciones/axa",
    component: ClaimsAxaPage,
    exact: true,
  },
  {
    path: "terminos-y-condiciones",
    component: TermsPage,
    exact: true,
  },
  {
    path: "politica-datos",
    component: PrivacyPolicyPage,
    exact: true,
  },
  {
    path: "tramites-y-reclamaciones/sbs",
    component: ClaimsSBSPage,
    exact: true,
  },
  {
    path: "samsung-care-plus/reclamaciones",
    component: ClaimsSamsungCarePlusPage,
    exact: true,
  },
  {
    path: "clausulados",
    component: ClausedPage,
    exact: true,
  },
];

export default routes;
