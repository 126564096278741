import React from "react";

interface IModalHeaderProps {}

const ModalHeaderDocs = () => {
  return (
    <div className="w-full border-b py-2 text-center">
      <h2 className="text-center text-2xl font-semibold">
        <span className="text-blueFinance">DOCUMENTACIÓN</span> | COBERTURAS
      </h2>
      <p className="text-md font-normal text-textBlack">
        Antes de realizar tu reclamación revisa los documentos necesarios
      </p>
    </div>
  );
};

export default ModalHeaderDocs;
