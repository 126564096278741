import React, { ReactElement, ReactNode } from "react";
import CardClaused from "./card.claused";

import LogoAxa from "../../../assets/images/axa_colpatria.png";
import LogoSBS from "../../../assets/images/sbs_white_black.png";
import LogoCardif from "../../../assets/images/logo_cardif.png";

export interface ClausedCard {
  nameProduct: string;
  buttonTitle: string;
  buttonNavPath: string;
  secureImage: ReactElement | ReactNode;
}

const ClausedComponet = () => {
  const clauseds: ClausedCard[] = [
    {
      nameProduct: "axa-colpatria",
      buttonTitle: "Ver Clausulado",
      buttonNavPath:
        "https://files-statics-protegeme.s3.us-east-1.amazonaws.com/P1648%20EQUIPOS%20TELEFONIA%20CELULAR_MAR%202022.pdf",
      secureImage: (
        <img src={LogoAxa} alt="axa_colpatria" className="mx-auto max-h-20" />
      ),
    },
    {
      nameProduct: "sbs",
      buttonTitle: "Ver Clausulado",
      buttonNavPath:
        "https://files-statics-protegeme.s3.us-east-1.amazonaws.com/CLAUSULADO%20SEGURO%20CELULARES%20SBS.pdf",
      secureImage: <img src={LogoSBS} alt="sbs" className="mx-auto max-h-20" />,
    },
    {
      nameProduct: "cardif",
      buttonTitle: "Ver Clausulado",
      buttonNavPath:
        "https://files-statics-protegeme.s3.us-east-1.amazonaws.com/CLAUSULADO%20SEGURO%20CELULARES%20CARDIF.pdf",
      secureImage: (
        <img src={LogoCardif} alt="cardif" className="mx-auto max-h-16" />
      ),
    },
  ];
  return (
    <section className="flex w-full justify-center p-0">
      <div className="container w-full px-6 py-24 lg:px-20">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          {clauseds.map((product, index) => (
            <CardClaused key={`${product.nameProduct}_${index}`} {...product} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ClausedComponet;
