import React from "react";
import { Helmet } from "react-helmet";
import Banner from "../../components/banner/Banner";
import Footer from "../../components/Footer/Footer";

import Img from "../../assets/images/products_banner.png";
import ClausedComponet from "./components/claused.component";

const ClausedPage = () => {
  return (
    <>
      <Helmet>
        <title>Clausulados | Acinco </title>
        <meta
          name="description"
          content="Podrás revisar los clausulados por si tienes alguna inquietud"
        />
      </Helmet>
      <Banner
        title={
          <h1 className="text-center text-5xl font-semibold text-textPrimary sm:text-left">
            Clausulados
          </h1>
        }
        paragraph={
          <p className="mt-5 text-center text-2xl text-textBlack sm:text-left">
            Podrás revisar los clausulados por si tienes alguna inquietud
          </p>
        }
        sectionImages={
          <div className="hidden h-full lg:block xl:-ml-3 xl:w-11/12 2xl:-ml-10 2xl:w-10/12">
            <img src={Img} className="h-auto max-w-full" alt="banner_img_1" />
          </div>
        }
        classNameContainer="pt-28 lg:py-40 lg:pb-24 lg:px-24"
      />
      <ClausedComponet />
      <Footer />
    </>
  );
};

export default ClausedPage;
