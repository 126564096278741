import React from "react";

const ModalBodyDocs = () => {
  const docsLegacy = [
    "Formato de reclamación debidamente diligenciado por el cliente, lo puedes descargar dando click en el siguiente link.",
    "Copia de la cédula de ciudadanía del cliente ampliado al 150%",
    "Para clientes pymes o corporativos se requiere: Certificado de cámara de comercio y fotocopia del documento de identificación (ampliado al 150%) del representante legal (persona jurídica).",
    "Puedes anexar la factura de compra legal del equipo, con esto nos ayudarías con el proceso y así tener una respuesta pronta.",
  ];

  const documents = [
    {
      title: "Calificado",
      titleAccent: "Hurto",
      docs: [
        ...docsLegacy,
        "Original o copia del denuncio ante la Fiscalía.",
        "Reporte del hurto debidamente registrado a los sistemas centralizados de la base de datos de proveedores de redes y servicios de telecomunicaciones móviles PRSTM (Listas Negras).",
      ],
    },
    {
      title: "Simple",
      titleAccent: "Hurto",
      docs: [
        ...docsLegacy,
        "Original o copia de la declaración Extra juicio autenticada que contenga como mínimo la siguiente información: Nombre completo del asegurado, número de identificación, dirección de residencia y del trabajo, teléfono de residencia y del lugar de trabajo, fecha, hora y lugar de ocurrencia, un recuento detallado de los hechos, número de la línea del celular extraviado, marca y modelo del equipo extraviado.",
        "Reporte del hurto debidamente registrado a los sistemas centralizados de la base de datos de proveedores de redes y servicios de telecomunicaciones móviles PRSTM (Listas Negras).",
      ],
    },
    {
      title: "Accidental",
      titleAccent: "Daño Total",
      docs: [
        ...docsLegacy,
        "Informe y diagnóstico del taller autorizado por Acinco.",
        "Equipo objeto del siniestro.",
      ],
    },
    {
      title: "de Pantalla",
      titleAccent: "Fractura",
      docs: [
        ...docsLegacy,
        "Imagen de la pantalla quebrada.",
        "Imagen del imei del equipo.",
      ],
    },
  ];

  return (
    <div className="w-full px-5">
      {documents.map((doc, index) => (
        <div key={`doc_${index}_${doc.title}`} className="w-full border-b py-3">
          <h2 className="text-2xl font-semibold ">
            <span className="text-blueFinance">{doc.titleAccent}</span>{" "}
            {doc.title}
          </h2>
          <ul className="ml-4 list-disc">
            {doc.docs.map((d) => (
              <li key={d} className="my-3 text-sm text-textBlack ">
                {d}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default ModalBodyDocs;
