import React from "react";
import { EmblaOptionsType } from "embla-carousel";

import LogoMotorola from "../../../assets/images/Motorola_Logo_White.png";
import LogoSamgung from "../../../assets/icons/samsung.svg";
import LogoItau from "../../../assets/images/itau.webp";
import LogoScotiabank from "../../../assets/images/scotiabank.png";
import LogoCelya from "../../../assets/images/celya.png";
import LogoBancolombia from "../../../assets/images/bancolombia.png";
import LogoTigo from "../../../assets/icons/Logo_Tigo.svg";
import Carousel from "../../../components/carousel/Carousel";

const ClientsSection = () => {
  const clients = [
    {
      key: "logo_tigo",
      component: (
        <img
          className="img-fluid lazy ml-3 mr-3 h-full max-w-20 sm:h-auto sm:max-h-16 sm:max-w-full"
          src={LogoTigo}
          alt="logo_tigo"
        />
      ),
    },
    {
      key: "logo_samsung",
      component: (
        <img
          className="img-fluid lazy ml-3 mr-3 h-full max-w-28  sm:h-auto sm:max-h-36 sm:max-w-full"
          src={LogoSamgung}
          alt="logo_samsung"
        />
      ),
    },
    {
      key: "logo_motorola",
      component: (
        <img
          className="img-fluid lazy ml-5 mr-3 h-2/5 max-w-32 sm:ml-3 sm:h-auto sm:max-h-14  sm:max-w-full"
          src={LogoMotorola}
          alt="logo_motorola"
        />
      ),
    },
    {
      key: "logo_celya",
      component: (
        <img
          className="img-fluid lazy ml-16 mr-3 h-2/5 max-w-28 sm:ml-3 sm:h-auto sm:max-h-14 sm:max-w-full"
          src={LogoCelya}
          alt="logo_celya"
        />
      ),
    },
    {
      key: "logo_itau",
      component: (
        <img
          className="img-fluid lazy ml-10 mr-3 h-3/5 max-w-28 sm:ml-3 sm:h-auto sm:max-h-16 sm:max-w-full"
          src={LogoItau}
          alt="logo_itau"
        />
      ),
    },
    {
      key: "logo_scotiabank",
      component: (
        <img
          className="img-fluid lazy ml-5 mr-3 h-1/3 max-w-32 sm:ml-3 sm:h-auto sm:max-h-16 sm:max-w-full"
          src={LogoScotiabank}
          alt="logo_scotiabank"
        />
      ),
    },
    {
      key: "logo_bancolombia",
      component: (
        <div className="flex justify-center">
          <img
            className="img-fluid lazy ml-5 mr-3 h-full max-w-28 sm:ml-3 sm:h-auto sm:max-h-48 sm:max-w-full"
            src={LogoBancolombia}
            alt="logo_bancolombia"
          />
        </div>
      ),
    },
  ];
  const options: EmblaOptionsType = { loop: true };
  return (
    <section className="flex w-full justify-center p-0">
      <div className="container w-full px-12 py-10">
        <div className="w-full text-center">
          <p className="text-2xl font-light">
            Hemos potencializado programas de seguros y asistencias para marcas
            como:
          </p>
        </div>
        <Carousel slides={clients} options={options} />
      </div>
    </section>
  );
};

export default ClientsSection;
