import React from "react";
import Img1 from "../../../assets/images/samsung_care_plus_banner.png";

const Banner = () => {
  return (
    <section className="w-full bg-whiteSoft">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 gap-4 pt-48 lg:mx-auto lg:grid-cols-2">
          <div className="mb-28 ml-2 pt-10 lg:mb-48 lg:ml-24 lg:pt-36">
            <h1 className="text-center text-5xl font-semibold text-textBlack sm:text-left">
              Reclamación
            </h1>
            <p className="mt-5 text-center text-2xl text-textBlack sm:text-left">
              ¡Es muy simple! Con tan solo 5 sencillos pasos puedes realizar tu
              reclamación.
            </p>
          </div>
          <div className="hidden items-center justify-center lg:flex">
            <img src={Img1} alt="banner_samsung" width="300" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
