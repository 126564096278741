import React from "react";
import StepProvider from "./stepper/stepper.provider";
import StepperHeader from "./stepper/stepper.header";
import StepperBody from "./stepper/stepper.body";
import ReclamationProvider from "../../providers/reclamation/reclamation.provider";

interface ICustomClaims {
  colorPrimary: string;
  dobleAuth?: boolean;
  privacyPolicy?: React.ReactNode;
}

const Claims = ({ colorPrimary, privacyPolicy, dobleAuth }: ICustomClaims) => {
  return (
    <ReclamationProvider>
      <StepProvider>
        <StepperHeader colorPrimary={colorPrimary} />
        <StepperBody
          colorPrimary={colorPrimary}
          privacyPolicy={privacyPolicy}
          dobleAuth={dobleAuth}
        />
      </StepProvider>
    </ReclamationProvider>
  );
};

export default Claims;
