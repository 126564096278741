import { AxiosResponse } from "axios";

import { microserviceApiAxios } from "../microservice-api.axios";
import { microserviceApiRoutes } from "../microservice-api.routes";
import {
  ICreateAdvisoryReqBody,
  ICreateAdvisoryRes,
} from "./advisory-service.interface";

export const generateAdvisory = (data: ICreateAdvisoryReqBody) => {
  const url = microserviceApiRoutes.v1.advisory.create();
  return microserviceApiAxios
    .post<Error, AxiosResponse<ICreateAdvisoryRes>>(url, data)
    .then((res) => res.data);
};
