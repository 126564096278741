import React from "react";

const Banner = () => {
  return (
    <section className="w-full">
      <div className="container mx-auto">
        <div
          className="mx-auto flex pt-48"
          style={{
            backgroundImage: "url(/reclamation/itau/background_banner.jpg)",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="mb-48 ml-24 pt-36">
            <h1 className="text-center text-5xl font-semibold text-textBlack sm:text-left">
              Reclamación
            </h1>
            <p className="mt-5 text-center text-2xl text-textBlack sm:text-left">
              ¡Es muy simple! Con tan solo 5 sencillos pasos puedes realizar tu
              reclamación.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
